<template>
  <div class="bbb">
    <van-nav-bar
      title="问诊"
      @click="chat"
      />
    <div id="Interrogation" class="message time_slot">
      <van-tabs v-model="active" @change="activeChange">
        <van-tab title="今日问诊"  name="问诊消息" />
        <van-tab title="可退款问诊" name="可退款问诊"   />
        <van-tab title="消息" name="系统消息"   />
      </van-tabs>
      <div  v-if="active == '问诊消息'">
        <div class="margin_top bpd" v-for="(item,index) of messageList">
          <van-steps :active="item.active"  active-color="#38f">
            <van-step>申请</van-step>
            <van-step>支付</van-step>
            <van-step>问诊</van-step>
            <van-step>开方</van-step>
            <van-step>审方</van-step>
            <van-step>完成</van-step>
          </van-steps>
          <div class="title display jcsb aic">
            <p class="title_name">
              <span class="title_icon">| </span>
              <span>{{ index+1. }} {{ consultType[item.consultType-1] }} （{{ item.pres_body.patient.name }}）</span>
            </p>
            <p>
              <van-button v-if="item.stateName == '待支付'" color="#02d4d8" size="small" @click="YBPayImg(item)"  class="message_btn">请支付</van-button>
              <van-button v-else-if="item.stateName == '待申请'" color="#02d4d8" size="small" @click="created_order(item)"  class="message_btn">申请问诊</van-button>
              <!-- && item.ih_type !=='NewYXHospital' -->
              <van-button v-else-if="item.stateName == '已完成' " color="#02d4d8" size="small" @click="openPres(item)" class="message_btn">下载</van-button>
              <van-button v-else-if=" item.ih_type == 'NewYXHospital' && item.pay_state == '3'" color="#02d4d8" size="small" @click="chat(item)" class="message_btn">请和医生沟通</van-button>
              <van-button v-else-if=" item.ih_type != 'NewYXHospital' && (item.pay_state == '1' || item.pay_state == '2' || item.pay_state == '3')" color="#02d4d8" size="small" @click="chat(item)" class="message_btn">请和医生沟通</van-button>
              <!-- <van-button color="#02d4d8" size="small" @click="chat(item)" class="message_btn">请和医生沟通</van-button> -->

            </p>
          </div>
          <div class="margin_top padding_lr_10">
            <span class="Medication_title">历史用药：</span>
            <span class="Medication color_20d8de" v-for="(t,i) of item.DrugsList">{{ t.name +'等'}}</span>
          </div>
          <div class="margin_top padding_lr_10 display jcsb padding_r_0">
            <div>
              <span class="Medication_title">申请时间：</span>
              <span class="Medication Medication_time">{{item.created_at.slice(10)}}</span>
            </div>
            <span class="message_state">
              {{item.stateName == '待支付' ? '' : item.stateName}} 
            </span>
          </div>
          <div class="margin_top padding_lr_10 display jcsb padding_r_0">
            <div>
              <span class="Medication_title">接诊医生：</span>
              <span class="Medication Medication_time">{{item.DoctorName}}</span>
            </div>
          </div>
          <div class="margin_top padding_lr_10 display jcsb padding_r_0" v-if="item.message">
            <div style="width: 100%;">
              <el-alert
                title="问诊耗时过长建议退款重新申请"
                type="warning"
                :closable="false"
                show-icon>
              </el-alert>
            </div>
          </div>
          <div class="margin_top padding_lr_10 display jcsb padding_r_0" v-if="messageType[item.stateName]">
            <div style="width: 100%;">
              <el-alert
                :title="messageType[item.stateName]"
                type="warning"
                :closable="false"
                show-icon>
              </el-alert>
             
            </div>
           
          </div>
          <div class="margin_top padding_lr_10 display jcsb padding_r_0" v-if="item.message">
            <div></div>
            <!-- <span class="message_state">
              <van-button  @click="refund(item)" class="message_btn" color="#E6A23C" size="small">申请退款</van-button>
            </span> -->
          </div>
          <div class="margin_top padding_lr_10 display jcsb padding_r_0"    v-if="item.ih_type == 'NewYXHospital' && item.reminderDoctor"  >
            <div></div>
            <span class="message_state">
              <van-button @click="AskUrge(item)"  color="linear-gradient(to right, #3AD468, #2DAB53)" size="small"  class="message_btn">催医生沟通</van-button>
            </span>
          </div>

        </div>
        <van-nodata v-if="messageList.length == 0"  :figure="imgUrl[0]" title="今日暂无问诊，历史问诊请进入我的页面进行查看"   />
        <div v-if="messageList.length <=1" style="display: flex; justify-content: center;margin-top: 1rem; position: relative;">
          <img style="width: 5rem; height: 5rem;" src="../assets/image/QRcode.jpg" alt="">
          <p style=" text-align: center; padding: 0 10px; font-weight: 600;">请保存截屏后长按该图片关注公众号，以更快获得问诊进展</p>
        </div>
      </div>
      <div  v-if="active == '可退款问诊'">
        <div class="margin_top bpd" v-for="(item,index) of messageList">
          <div class="title display jcsb aic">
            <p>
              <span class="title_icon">| </span>
              <span>{{ index+1. }} {{ consultType[item.consultType-1] }}</span>
            </p>
            <p>
              <van-button color="#02d4d8" size="small" @click="refund(item)" class="message_btn">申请退款</van-button>
            </p>
          </div>
          <div class="margin_top padding_lr_10">
            <span class="Medication_title">历史用药：</span>
            <span class="Medication color_20d8de" v-for="(t,i) of item.DrugsList">{{ t.name +'等'}}</span>
          </div>
          <div class="margin_top padding_lr_10 display jcsb padding_r_0">
            <div>
              <span class="Medication_title">申请时间：</span>
              <span class="Medication Medication_time">{{item.created_at}}</span>
            </div>
            <span class="message_state">{{item.stateName}}   </span>
          </div>
  
        </div>
        
        <van-nodata v-if="messageList.length == 0"  :figure="imgUrl[0]" title="仅显示最近7天可退款问诊"   />
        <div v-if="messageList.length <=1" style="display: flex; justify-content: center;margin-top: 1rem; position: relative;">
          <img style="width: 5rem; height: 5rem;" src="../assets/image/QRcode.jpg" alt="">
          <p style=" text-align: center; padding: 0 10px; font-weight: 600;">请保存截屏后长按该图片关注公众号，以更快获得问诊进展</p>
        </div>
      </div>
      <div v-if="active == '系统消息'">
        <div class="margin_top bpd" v-for="(item,index) of News">
          <div class="title display jcsb aic">
            <p>
              <span class="title_icon">| </span>
              <span>系统消息</span>
            </p>
            <p>
              <span class="message_state">{{item.stateName}}</span>
            </p>
          </div>
          <div class="margin_top padding_lr_10">
            <span class="Medication_title">{{item.txt}}</span>
          </div>
          <div class="margin_top padding_lr_10 display jcsb padding_r_0">
            <div>
              <span class="Medication_title">时间：</span>
              <span class="Medication ">{{item.created_at.slice(10)}}</span>
            </div>
          </div>
  
        </div>
        <van-nodata v-if="News.length == 0" :figure="imgUrl[0]" title="暂无消息"   />
      </div>
      
      <footer_/>
    </div>
  </div>
</template>
<script>
import footer_ from '../components/footer.vue'
import method from '../assets/js/util.js'
import mixin from "@/utils/mixin";
// import { TUIConversation,  } from "../TUIKit";
// import {  TUIStore,StoreName,conversationList} from "@tencentcloud/chat-uikit-engine";
export default {
  mixins: [mixin],
  components: {
    footer_,
    // TUIConversation,
  },
  data() {
    return {
      active:'问诊消息',
      messageList:[],
      messageType:{
        '已支付待接诊':'请耐心等待医生接诊，约一分钟',
        '已接诊':'请点击右上方按钮和医生沟通',
        '咨询中':'请点击右上方按钮和医生沟通',
        '超时已取消':'请重新申请问诊',
        '未支付已过期':'请重新申请问诊',
        '订单咨询已过期':'请重新申请问诊',
        '拒绝接单':'请重新申请问诊',
        '问诊被结束':'请重新申请问诊',
        '审核通过':'请去药店医保客户端使用此统筹处方',
        '审核中':'请耐心等待审核即可，约一分钟',
        '待审核':'请耐心等待审核即可，约一分钟',
        '待问诊':'请耐心等待医生接诊，约一分钟',
        '问诊中':'请点击右上方按钮和医生沟通',
        '审核不通过':'等待医生重新提交处方即可',


        '待支付':'请点击上方按钮进行支付',
        '待申请':'请点击上方按钮申请问诊',
        '已完成':'请去药店医保客户端使用此统筹处方',
        "医生重新开方中":'医生正在重新开方中，请等待即可'
      },
      currentConversationID:'',
      wxchatImg:require('../assets/image/QRcode.jpg'),
      srcList:[require('../assets/image/QRcode.jpg'),require('../assets/image/QRcode.jpg')],
      consultType:[
        '图文咨询',
        '电话咨询',
        '视频咨询',
        '复方购药',
        '急速问诊',
      ],
      News:[],
      times:null
    }
  },
  methods: {
    //催医生沟通
    async AskUrge(item){
      let userInfo = JSON.parse(localStorage.getItem('userinfo'))
      let res = await this.$api.article.InternetHospitalGet({search_db:'AskUrge',ih_type:localStorage.getItem('ih_type')},{id:item.id,pharmacy_id:item.pharmacy_id,user_id:userInfo.user_id})
      if(res.data.code == '200'){
        this.$message({
          message: '已催医生，请稍后',
          type: 'success'
        });
      }
    },
    //申请问诊
    created_order(item){
        let data = {
            doctor_id:item.doctorId,
            pres_id:item.id,
        }
        this.$api.article.CreatedInternetPres(data).then(res=>{
            this.$message({
              message: '申请问诊成功',
              type: 'success'
            });
            this.loadList()
        })
    },
    async chat(item){
      let imChatId = `C2C${item.doctorId}`
      
      await this.IMSIG(item.ih_type,item.id)
      let that = this
      if(item.ih_type == 'NewYXHospital'){
          imChatId = `GROUP${item.pres_id}`
      }
      sessionStorage.setItem('ChatPrseInfo', JSON.stringify({
          chatId:imChatId,
          pres_id:item.pres_id,
      }))
      if( item.if_first_chat == '0'){
        that.putstate('Pres','id',item.id,{'if_first_chat':1})
        that.ChatMessage( item.ih_type == 'NewYXHospital' ?item.pres_id :item.doctorId,item.doctorId,item.pres_body,item,item.ih_type == 'NewYXHospital' ? 'GROUP':'C2C')
        that.$router.push({
            name:'chat',
            query:{
              chatId:imChatId,
              pres_url:item.pres_url,
              pres_id:item.pres_id,
              doctorId:item.doctorId,
              id:item.id,
              pharmacy_id:item.pharmacy_id,
              userName:item.pres_body.patient.name,
              doctorName:item.DoctorName,
              
            }
          })
          
      }else{
         that.$router.push({
            name:'chat',
            query:{
              chatId:imChatId,
              pres_url:item.pres_url,
              pres_id:item.pres_id,
              doctorId:item.doctorId,
              id:item.id,
              pharmacy_id:item.pharmacy_id,
              userName:item.pres_body.patient.name,
              doctorName:item.DoctorName,
            }
          })
      }
    },
    async loadList(data = {query:{found_id:localStorage.getItem('userrole_id')}}) {
      if(!localStorage.getItem('userrole_id')){
        return
      }
      let that = this
      setTimeout(() => {
        that.loading.close();
      }, 5000);
      try {
          
          // 发起异步请求获取文章列表数据
          // start_time:this.date[0],end_time:this.date[1]
          // start_time:'2024-07-23 00:00:00',end_time:'2024-07-30 00:00:00'
          const articleRes = await this.$api.article.query({search_db:'Pres',query_type:'equal',start_time:this.date[0],end_time:this.date[1]},data);
          const articleData = articleRes.data.result;
          if(articleData.length !==0 && articleData[0].doctorId == 'None'){
            this.$api.article.get_doctor(articleData[0].id)
          }
          articleData.forEach((element,index) => {
            this.$api.article.query(
              {search_db:'Doctor',query_type:'first',},
              {
                query:{
                  doctor_id:element.doctorId
                }
              }
            ).then(res=>{
              this.$set(this.messageList[index],'DoctorName',res.data.result.name)
            })
          });
          // 使用 Promise.all() 等待所有的异步请求完成
          await Promise.all(articleData.map(async (element) => {
              element.pres_body = JSON.parse(element.pres_body);
              element['active'] = this.getactive(element)
              element['message'] = this.isTimeDifferenceGreater(element)
              element['DrugsList'] = [element.pres_body.Drugs[0]];
              let pay_state = [
                '待支付',
                '已支付待接诊',
                '已接诊',
                '咨询中',
                '已完成',
                '超时已取消',
                '未支付已过期',
                '订单咨询已过期',
                '',
                '拒绝接单',
                '退款成功',

                ]
                element['stateName'] = '';
                if (element.doctorId =='None' ) {
                    element.stateName = '待接单';
                    return;
                }
                if (element.pres_id =='None' ) {
                    element.stateName = '待申请';
                    return;
                }
                if(element.pay_state  == '0'){
                  element.stateName = pay_state[element.pay_state*1]
                  return
                }
                if (element.state =='adopt' ) {
                    element.stateName = '已完成';
                    return;
                }
              
              if((element.pay_state*1 >4 && element.pay_state*1 <=10) || element.pay_state == '2' || element.pay_state == '1'){
                element.stateName = pay_state[element.pay_state*1]
              }else{
                if(element.pay_state == '4'){
                  if(element.pharmacistStatus == '2'){
                    element.stateName = '已完成';
                  }else{
                    element.stateName = '问诊被结束';
                  }
                }else if(element.pay_state == '3'){
                  if(element.pharmacistStatus == '3'){
                    element.stateName = '医生重新开方中';
                  }else if(element.pharmacistStatus == '2'){
                    element.stateName = '审核通过';
                  }else if(element.pharmacistStatus == '1'){
                    element.stateName = '审核中';
                  }else{
                      let doctorCheckStatusAll = [
                          '待问诊',
                          '问诊中',
                          '已完成',
                          '审核不通过',
                      ];
                      element.stateName = doctorCheckStatusAll[element.doctorCheckStatus];
                  }
                }
              }
              element['reminderDoctor'] = this.isreminderDoctor(element)

             
          }));

          // 所有的异步请求都返回后，设置 messageList
          this.messageList = articleData;
          this.loading.close();

         
      } catch (error) {
          console.error('Error occurred:', error);
      }
    },
    getactive(item){
      let start = 0;
      if(item.pres_id !== 'None'){
        start +=1
      }
      if(item.pay_state >0){
        start +=1
      }
      if(item.doctorCheckStatus*1<3){
        start += item.doctorCheckStatus*1
      }
      if(item.pharmacistStatus >0){
        start +=1
      }
      if(item.state == 'adopt'){
        start = 6
      }
      return start
   
    },
    isTimeDifferenceGreater(item){
      var d = new Date().getTime()
      if(method.getFutureDate(method.formatDate(d),item.created_at,35) && item.doctorCheckStatus < 1 && item.pay_state > 0 && item.pay_state < 5){
        return true
      }
      return false
    },
    //判断是否可以催单
    isreminderDoctor(item){
      var d = new Date().getTime()
      return method.getFutureDate(method.formatDate(d),item.created_at,5) && item.doctorId !=='None' && item.state !=='adopt' && item.pay_state !=10 && item.stateName !=='超时已取消'
    },
    //刷新支付状态
    Refresh_pay_state(item){
      this.$api.article.syncPres(item.pres_id).then(res=>{
        if(res.data.result.pay_state !== '0'){
          this.activeChange()
        }
      })
    },
    activeChange(){
      if(this.active == '可退款问诊'){
        this.date[0] = method.formatDate(method.getFutureDate(this.date[0],7,'-'),'yyyy-MM-dd')+ ' 00:00:01'
        this.loadList(
          {query:{found_id:localStorage.getItem('userrole_id'),pay_state:'1'}}
        )
      }else if(this.active == '问诊消息'){
        var d = new Date().getTime()
        this.date = [method.formatDate(d).slice(0,10)+ ' 00:00:01',method.formatDate(d).slice(0,10)+' 23:59:59']
        this.loadList(
          {query:{found_id:localStorage.getItem('userrole_id')}}
        )
      }
    },
    openPres(item){
      console.log(item)
      if(item.ih_type == 'LodYXHospital'){
        window.location.href= item.presDownUrl
      }else{
        // InternetHospitalGet
        let params = {
          search_db:'Pres'
        }
        let data = {
          id:item.id,
          pharmacy_id:item.pharmacy_id
        }
        this.$api.article.InternetHospitalGet(params,data).then(res=>{
          console.log(res)
          if(res.data.result.data){
            res.data.result.data.presInfo = JSON.parse(  res.data.result.data.presInfo)
            let item_url =  res.data.result.data.presInfo.find(item=>{return item.url})
            if(item_url){
              window.location.href =  item_url.url
            }
          }
        })
      }
    },
    async imLogin(id,Sig){
      const res = await this.$tim.Utils.imLogin(id,Sig)
    },
    // 跳转支付连接
    YBPayImg(item){
      let pres_id = item.pres_id
        let that = this
        this.$api.article.YBPayImg(pres_id,1,localStorage.getItem('ih_type')).then(res=>{
          if(res){
            this.$alert('请支付成功后重新进入问诊', '提示', {
            confirmButtonText: '确定',
            callback: action => {
                sessionStorage.removeItem('Drugs');
                sessionStorage.removeItem('Current');
                sessionStorage.removeItem('disease');
                sessionStorage.removeItem('patientId');
                if(res.data.result.data.slice(0,4) == 'http'){
                    window.location.href= res.data.result.data
                }else{
                    that.$router.push('/message')
                }
            }
            });
          }
        })
    },
    refund(item){
      let pres_id = item.pres_id
        let that = this
        this.$api.article.YBPayImg(pres_id,2,item.ih_type).then(res=>{
          this.$alert('请确认是否退款', '提示', {
            confirmButtonText: '确定',
            callback: action => {
                sessionStorage.removeItem('Drugs');
                sessionStorage.removeItem('Current');
                sessionStorage.removeItem('disease');
                sessionStorage.removeItem('patientId');
                if(res.data.result.data.slice(0,4) == 'http'){
                    window.location.href= res.data.result.data
                }else{
                    that.$router.push('/message')
                }
            }
            });
        })
    },
  },
  created() {
    var d = new Date().getTime()
    this.date = [method.formatDate(d).slice(0,10)+ ' 00:00:01',method.formatDate(d).slice(0,10)+' 23:59:59']

     // 发起异步请求获取新闻列表数据
     this.$api.article.query({search_db:'News',query_type:'equal',start_time:this.date[0],end_time:this.date[1]},{query:{userrole_id:localStorage.getItem('userrole_id')}}).then(res=>{
      this.News = res.data.result;
      let that = this;
        this.loading = this.$loading({
        lock: true,//lock的修改符--默认是false
        text: '加载中。。。',    //显示在加载图标下方的加载文案
        color:'rgba(255, 255, 255, 1)',
        spinner: 'el-icon-loading',//自定义加载图标类名
        background: 'rgba(0, 0, 0, 0.9)',//遮罩层颜色
        target: document.querySelector('#table')//loadin覆盖的dom元素节点
        });

        setTimeout(() => {
      // 在IMSIG函数执行完后执行的操作
        // 在这里添加其他操作
        // that.IMSIG()
        if(localStorage.getItem('userinfo')&&localStorage.getItem('userrole_id')){
          clearInterval(that.times);
          that.activeChange()
          that.times = setInterval(function() {
            if(that.$route.name == 'message'){
              that.activeChange()
            }
        }, 10000);
        }
    }, 1000);
     })
     
 
    
   
  },
  mounted(){
  },
  destroyed() {
    clearInterval(this.times);
  },
}
</script>
<style scopde lang="less">
.display{
  display: flex;
}
.jusb{
  justify-content: space-between;
}
.title_name{
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}
.van-step--horizontal .van-step__active-circle{
  width: 20px;
  height: 20px;
  // color: #000;
}

.van-nodata{
  top: 6rem
}
.van-nodata__figure{
  width: 10rem;
  height: 10rem;
}
.message .Medication_time{
  font-size:12px;
}
.message .van-button--small{
  width: 130px;
}
.message_btn{
  border-radius: 30px !important;
}
.Medication{
  font-size: 14px;
  padding: 0 10px;
}
.Medication_title{
  // color: #000;
  font-weight: 500;
  font-size: 14px;
}
.message_state{
  color: #e65e5e;
  font-weight: 500;
}
.van-tab--active{
  color: #20d8de !important;
}
.van-tabs__line{
  background-color:#20d8de !important;
}
.title{
  font-size: 16px;
  color: #000;
  font-weight: 600;
}
.margin_top{
  margin-top: 0.4rem !important;
}
.bpd{
  background: #fff;
  padding: 10px;
  border-radius: 6px;
}
#Interrogation{
  // background: #fff;
  height: calc(100% - 100px);
  width: 100%;
  margin: 0 auto;
  padding: 0.5rem;
  border-radius: 6px;
  overflow: auto;
}
.header_img{
  width: 100%;
  height: 16.5rem;
  img{
    width: 100%;
    height: 10rem;
  }
  .yaowu{
    margin: 0 auto;
    display: flex;
    color: #999999;
    font-size: 14px;
    justify-content: space-between;
    .yaowu_title{
      color: #000;
    }
  }
}
.scyy{
  .scyy_btn{
    display: flex;
    justify-content: space-between;
    .van-button--small{
      height: 3rem;
    }
  }
}
.zxxx_nr{
  display: flex;
  .zxxx_txt{
    width: 60%;
    .zxxx_title{
      font-size: 15px;
      color: #000;
      font-weight: 600;
    }
    p{
      font-size: 10px;
    }
    .zxxx_time{
      margin-top: 1rem;
    }
  }
  .zxxx_img{
    width: 40%;
    height: 5rem;
    border-radius: 2px;
    overflow: hidden;
    img{
      width: 100%;
      height: 100%;
    }
  }
}
.padding_lr_10{
  padding: 0 20px;
}
</style>
<style  lang="less">
.van-button--normal{
  font-size: 0.8rem !important;
}
.padding-4{
  padding:0 4.9%;
}
.padding-l-20{
  padding-left: 20px;
}
.padding_r_0{
  padding-right: 0 !important;
}
.padding_lr_10{
  padding: 0 20px;
}
.color_20d8de{
      color: #20d8de;
  }
.display{
  display: flex;
}
.jcsb{
  justify-content: space-between;
}
.aic{
  align-items: center;

}
.van-nav-bar{
  background: rgba(255, 255, 255, 0) !important;
}
.van-nav-bar__title{
  color: #fff !important;
  font-weight: 600 !important;
}
.title{
  font-size: 16px;
  color: #000;
  font-weight: 600;
}

.bpd{
  background: #fff;
  padding: 10px;
  border-radius: 6px;
}
#Interrogation{
  height: calc(100% - 49px);
  width: 100%;
  margin: 0 auto;
  padding: 0.5rem;
  border-radius: 6px;
  overflow: auto;
}
.header_img{
  width: 100%;
  height: 20rem;
  img{
    width: 100%;
    height: 10rem;
  }
  .yaowu{
    margin: 0 auto;
    display: flex;
    color: #999999;
    font-size: 14px;
    justify-content: space-between;
    .yaowu_title{
      color: #000;
    }
  }
  button{
    font-size: 1rem !important;
    height: 4rem;
  }
}
.scyy{
  background: linear-gradient(to right,#fdd8d3,#fee4dd,#fff2e8) !important;
  height: 7.4rem;

  .scyy_btn{
    display: flex;
    // margin-left: 10px;
    // justify-content: space-between;
    .van-button--small{
      margin-right: 10px;
      height: 2rem;
    }
  }
}
.zxxx_nr{
  display: flex;
  .zxxx_txt{
    width: 60%;
    .zxxx_title{
      font-size: 15px;
      color: #000;
      font-weight: 600;
      line-height: 20px;
      margin-top: 10px;
    }
    p{
      font-size: 10px;
      line-height: 20px;
    }
    .zxxx_time{
      margin-top: 10px !important;
    }
  }
  .zxxx_img{
    width: 40%;
    height: 5rem;
    border-radius: 2px;
    overflow: hidden;
    img{
      width: 100%;
      height: 100%;
    }
  }
}
</style>